import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';

export const itiInit = (phoneInput) => {
	return intlTelInput(phoneInput, {
		// allowExtensions: true,
		preferredCountries: ["KZ", "RU", "UA", "BY"],
		nationalMode: false,
		initialCountry: "KZ",
		formatOnDisplay: true,
		isValidNumber: true,
		separateDialCode: true,
		utilsScript: utilsScript
	});
}