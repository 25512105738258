const formValidate = (status, message, el, feedback) => {
  if (el) {
    if (status === 'invalid') {
      if (
        !el.classList.contains(`is-${status}`) ||
        el.classList.contains(`is-valid`)
      ) {
        el.classList.remove('is-valid')
        el.classList.add(`is-${status}`)
      }
    }
    if (status === 'valid') {
      if (
        !el.classList.contains(`is-${status}`) ||
        el.classList.contains(`is-invalid`)
      ) {
        el.classList.remove('is-invalid')
        el.classList.add(`is-${status}`)
      }
    }
  }

  if (feedback) {
    if (status === 'invalid') {
      if (
        !feedback.classList.contains(`${status}-feedback`) ||
        feedback.classList.contains(`valid-feedback`)
      ) {
        feedback.classList.remove(`valid-feedback`)
        feedback.classList.add(`${status}-feedback`)
      }
      feedback.innerText = message
      feedback.style.display = 'block'
    }
    if (status === 'valid') {
      if (
        !feedback.classList.contains(`${status}-feedback`) ||
        feedback.classList.contains(`invalid-feedback`)
      ) {
        feedback.classList.remove(`invalid-feedback`)
        feedback.classList.add(`${status}-feedback`)
      }
      feedback.innerText = message
      feedback.style.display = 'block'
    }
  }
}

export default formValidate
