import formValidate from './form-validation'

const validatePhone = (iti, inputsPhone) => {
  const feedback = inputsPhone.parentElement.parentElement.children[2]
  const phone = iti.getNumber()
  if (iti.getValidationError() === 3)
    return formValidate(
      'invalid',
      'Номер телефона слишком длинный',
      inputsPhone,
      feedback
    )
  if (iti.getValidationError() === 2)
    return formValidate(
      'invalid',
      `В номере ${phone} не хватает цифр`,
      inputsPhone,
      feedback
    )
  if (iti.getValidationError() !== 0)
    return formValidate(
      'invalid',
      'Номер телефона введен некорректно',
      inputsPhone,
      feedback
    )
  formValidate('valid', `Номер ${phone} верный!`, inputsPhone, feedback)
  return true
}

export default validatePhone
