const disabledBtn = (btn, set) => {
  // Disabled btn
  if (set) {
    btn.setAttribute('disabled', '')
    return btn.classList.remove('flasher')
  }
  // Return active btn
  if (!set) {
    btn.removeAttribute('disabled')
    return btn.classList.add('flasher')
  }
}

export default disabledBtn
