import { itiInit } from './../iti/iti-init'
import disabledBtn from './../btn/disabled-btns'
import validatePhone from './validations/phone-iti-validation'
import formValidate from './validations/form-validation'

const offerForm = document.querySelectorAll('form.form-offer')

if (offerForm) {
  offerForm.forEach((form) => {
    const inputPhone = document.querySelector(`form#${form.id} input.phone`)
    const btn = document.querySelector(`form#${form.id} button`)
    const policy = document.querySelector(`form#${form.id} .policy`)
    let iti

    if (inputPhone) {
      iti = itiInit(inputPhone)
      inputPhone.addEventListener('change', (e) =>
        validatePhone(iti, inputPhone)
      )
    }

    if (policy) {
      policy.oninput = () => {
        if (!policy.checked) {
          formValidate('invalid', '', policy)
          return disabledBtn(btn, true)
        }
        formValidate('valid', '', policy)
        return disabledBtn(btn, false)
      }
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault()
      const data = {}

      if (inputPhone && validatePhone(iti, inputPhone) === true) {
        // data
        data.phone = iti.getNumber()
        data.website = location.origin
        data.page = location.pathname
        data.offer = document.querySelector(
          `form#${form.id}`
        ).parentElement.children[0].textContent
        data.utm = location.href.split('?')[1]
        // Submit Form
        import('./submits/submit-default').then((module) =>
          module.default(`${location.origin}/api/v1/lead`, data)
        )
      }
    })
  })
}
